<template>
  <div class="bg" style="background: #f6f6f6;">
    <div class="pdf-wrapper" v-if="pdf_src" :style="{width:pdf_div_width}" >
      <canvas
        v-for="page in pdf_pages"
        style="display: none;"
        :id="'the-canvas' + page"
        :key="page"
      ></canvas>
      <div class="load-more" ref="loadBtn" @click="loadMore">点击加载更多</div>
    </div>

    <div v-else class="no-data">暂无数据</div>
  </div>

</template>
<script setup>
import { getVideoParts } from "@/api";
import { ref } from "vue";
import { useRoute } from "vue-router";
import * as PDFJS from "pdfjs-dist/build/pdf.js";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.js";
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const route = useRoute();
const pdf_scale = ref(1);
const pdf_pages = ref([]);
const pdf_div_width = ref("");
const pdf_src = ref(null);
const currentPage = ref(0);
const pdfInstance = ref(null);
const loadBtn = ref(null);

getVideoParts({
  page: 0,
  limit: 100,
  id: route.params.id,
}).then((res) => {
  pdf_src.value = res.data?.[0]?.url;
  if (pdf_src.value) {
    _loadFile(pdf_src.value);
  } else {

  }
});

const _loadFile = (url) => {
  //初始化pdf
  let loadingTask = PDFJS.getDocument(url);
  loadingTask.promise.then((pdf) => {
    pdfInstance.value = pdf;
    pdf_pages.value = pdf.numPages;
    setTimeout(() => {
      _renderPage(currentPage.value * 20 + 1, pdf, 20);
    });
  });
};

const _renderPage = (num, pdf, limit) => {
  //渲染pdf页
  pdf.getPage(num).then((page) => {
    const canvas = document.getElementById("the-canvas" + num);
    canvas.style.display = "block";
    const ctx = canvas.getContext("2d");
    const dpr = window.devicePixelRatio || 1;
    const bsr =
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1;
    const ratio = dpr / bsr;
    const viewport = page.getViewport({ scale: pdf_scale.value });


    let width = viewport.width
    let height = viewport.height
    let scle = 1
    if(viewport.width > window.innerWidth) {
      scle = window.innerWidth / viewport.width
      width = window.innerWidth
      height = viewport.height * scle
    } 

  

    canvas.width = width * ratio;
    canvas.height = height * ratio;

    canvas.style.width = width + "px";

    pdf_div_width.value = width + "px";

    canvas.style.height = height + "px";

    ctx.setTransform(ratio * scle, 0, 0, ratio * scle, 0, 0);
    const renderContext = {
      canvasContext: ctx,
      viewport: viewport,
    };
    page.render(renderContext);

    if (pdf_pages.value > num && num <= limit) {
      setTimeout(() => {
        _renderPage(num + 1, pdf, limit);
      }, 100)
    } else if (pdf_pages.value <= num) {
      console.log("加载完成");
      loadBtn.value.remove();
    }

  });
};

const loadMore = () => {
  currentPage.value += 1;
  _renderPage(currentPage.value * 20, pdfInstance.value, currentPage.value * 20 + 20);
};
</script>
<style>
body,
#app,
html,
.bg{
  height: 100%;
  width: 100%;
}

.bg { 
  overflow: auto;
}

.pdf-wrapper {
  font-size: 0;
  margin: 0 auto;
}

.load-more {
  text-align: center;
  padding: 40px;
  color: #999;
  cursor: pointer;
  font-size: 14px;
}

.no-data {
  text-align: center;
  padding: 40px;
  color: #999;
  cursor: pointer;
  font-size: 14px;
  height: 100%;
  line-height: 80vh;
}
</style>
